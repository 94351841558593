import React from 'react';
import styled from 'styled-components';


const Outer = styled.div`
  max-width: 300px;
  margin: 0 auto;
`;

const Header = styled.header`
  padding: 10px 5px;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
`;

const HeaderInner = styled.div`
  font-size: 25px;
    font-weight: bold;
  padding-left: 15px;
`;

const Main = styled.main`
  margin: 0 auto;
`;

const LogoWrapper = styled.div`
  border-radius: 50%;
  background: #fff;
  border: 1px solid #000;
  height: 40px;
  width: 40px;
  overflow: hidden;
`;

const Logo = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

function Wrapper({ children, domain }) {
    return (
        <Outer>
            <Header>
                    <LogoWrapper>
                        <Logo src={domain.logo_url}/>
                    </LogoWrapper>
                    <HeaderInner>
                        {domain.name}
                    </HeaderInner>
            </Header>
            <Main>
                {children}
            </Main>
        </Outer>
    );
}

export default Wrapper;
