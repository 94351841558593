import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { StateProvider } from './store.js';
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Open sans", Arial, Helvetica, sans-serif;

   
  }

  body {
    background: #F8F8FF;
    -webkit-user-select: none;
      -moz-user-select: -moz-none;
        -ms-user-select: none;
            user-select: none;
  }
`

const Version = styled.div`
  font-size: 10px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1;
`;

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <ServiceWorkerWrapper>
        <App />
        <Version>
          {process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : 'Development'}
        </Version>
      </ServiceWorkerWrapper>
      <GlobalStyle />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

