import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import styled from 'styled-components';
import useOnlineStatus from '@rehooks/online-status';

import { motion, useAnimation } from "framer-motion";
import Api from './Api';
import Wrapper from './Wrapper';
import { store } from './store';
import SwipeButton from './SwipeButton';
import Token from './Token';


const Loading = styled.div`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const CodeWrapper = styled.div`
  background: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
`;
const CodeLabel = styled.div`
  font-size: 10px;
  color: #000;
  line-height: 1;
  margin-bottom: 5px
`;

const CodeText = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: #000;
`;

const CodeHideWrapper = styled.div`
  text-align: center;
  padding-top: 5px;
`;

const CodeHide = styled.a`
  color: #000;
  font-size: 12px;
  text-decoration: underline;
`;


const DoorWrapper = styled.div`
  margin: 10px auto 20px;
  ${({ danger }) => danger && `
    background: #ffcccb;
  `}
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

  //box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

const DoorContent = styled.div`
  padding: 20px;

`;

const DoorTitle = styled.div`
  font-size: 25px;
  line-height: 1;
  font-weight: bold;
`;

const DoorSubtext = styled.div`
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.5;
`;

const LogoutButton = styled.button`
  -webkit-appearance: none;
  border: none;
  background: transparent;
  display: block;
  margin: 50px auto 0;
`;


function Door({ door, geolocation }) {
    const buttonRef = useRef(null);


    const resetButton = () => {
        buttonRef.current.reset();
    };

    const openDoor = async () => {
        if (door.disabled) {
            return;
        }

        return Api.post(`/doors/${door.id}`)
            .then(() => {
                setTimeout(() => {
                    resetButton();
                }, 2000);
            }).catch((error) => {
                setTimeout(() => {
                    resetButton();
                }, 2000);

                return Promise.reject(error);
            });

    };


    return (
        <DoorWrapper>
            <DoorContent>
                <DoorTitle>
                    {door.title}
                </DoorTitle>
                {door.subtitle && (
                    <DoorSubtext>
                        {door.subtitle}
                    </DoorSubtext>
                )}

            </DoorContent>
            <SwipeButton
                ref={buttonRef}
                key={door.id}
                loadingColor="#0099cc"
                successColor="#7dca5c"
                failureColor="#b32d00"
                textUnlocked="Door unlocked"
                textFailed="Opening door failed"
                loadingText="Opening..."
                color="#7dca5c"
                text="Slide to open door"
                onSuccess={openDoor}
            />
        </DoorWrapper>
    );
}


function Doors({ domain }) {
    const [doors, setDoors] = useState([]);
    const [alarmCode, setAlarmCode] = useState(null);
    const [loading, setLoading] = useState(true);
    const { dispatch } = useContext(store);

    useEffect(() => {
        Api.get('/doors')
            .then((r) => {
                setDoors(r.data);
                setLoading(false);
            }).catch(() => {
            dispatch({ type: 'reload' });
        });
    }, []);

    const viewAlarmCode = () => {
        Api.post('/code')
            .then((r) => {
                setAlarmCode(r.data.alarm_code);
            });

    };

    const reloadHard = () => {
        window.location.reload(true);
    };

    const logout = () => {
        dispatch({ type: 'logout' });
        Token.remove();
    }


    if (loading) {
        return <Loading>
            Loading...
        </Loading>;
    }

    return (
        <Wrapper domain={domain}>
            {
                doors.map((door) => (
                    <Door key={`${door.id}`} door={door}/>
                ))
            }

            <LogoutButton onClick={logout}>Logout</LogoutButton>

        </Wrapper>
    );
}

export default Doors;
