const tokenStorage = "dwi-token";

function getParameterByName(name, url = null) {
    let realUrl = url;
    if (url === null) {
        realUrl = window.location.href;
    }
    const realName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${realName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(realUrl);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default class Token {
    static get() {
        const urlToken = getParameterByName('t');
        if (urlToken !== null) {
            Token.set(urlToken);
            window.location.href = "/";

            return null;
        }

        return localStorage.getItem(tokenStorage);
    }

    static set(token) {
        localStorage.setItem(tokenStorage, token);
    }

    static remove() {
        localStorage.removeItem(tokenStorage);
    }

    static getData(data) {
        const token = Token.get();
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const json = JSON.parse(window.atob(base64));
        return json[data];
    }

}

