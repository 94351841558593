import React, { Component } from 'react';
import styled from 'styled-components';

/* add css styles here (optional) */

const ReactSwipeButton = styled.div`
  width: 100%;
  height: 50px;
  position: relative;

  &,
  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
`;


const RsbContainer = styled.div`
  float: left;
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 10px;
  position: relative;
  /* box-shadow: inset 1px 1px 4px rgba(0,0,0,0.1); */
  overflow: hidden;

  ${props => props.unlocked ? `
  cursor: default;

  ${RsbcSliderCircle},
  ${RsbcSliderArrow} {
    transition: 0.5s;
    margin-right: -60px;
  }

  ${RsbcSlider} {
    left: 100% !important;
    cursor: default;
    pointer-events: none;
  }
  ` : ''}
`;


const RsbcSlider = styled.div`
  float: left;
  width: 100%;
  position: absolute;
  height: 50px;
  top: 0;
  left: 50px;
  margin-left: -100%;
  background: #333;
  border-radius: 10px;
  z-index: 100;
  /* box-shadow: 1px 1px 5px rgba(0,0,0,0.3); */
  cursor: pointer;
  background: ${props => props.bgColor};

`;

const RsbcSliderText = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 50px;
  text-align: center;

  color: #fff;
  font-size: 13px;
`;
const RsbcSliderArrow = styled.span`
  position: absolute;
  fill: #fff;
  height: 24px;
  width: 24px;
  top: 16px;
  right: 13px;
  transform-origin: center;
  z-index: 10;
`;
const RsbcSliderCircle = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background: ${props => props.bgColor};
`;
const RsbcText = styled.div`
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
`;


const isTouchDevice = 'ontouchstart' in document.documentElement;

export default class SwipeButton extends Component {

    isDragging = false;
    sliderLeft = 0;

    static defaultProps = {
        successColor: '#00ff00',
        errorColor: '#ff0000',
        loadingColor: '#AAA',
        text: 'Swipe to unlock',
        textUnlocked: 'Unlocked',
        textFailed: 'Failed',
        loadingText: 'Loading...',
        onSuccess: () => {
        },
    };

    state = {
        unlocked: false,
        failed: false,
        loading: false,
    };

    constructor(props) {
        super(props);

        this.slider = React.createRef();
        this.container = React.createRef();
    }

    componentDidMount() {
        if (isTouchDevice) {
            document.addEventListener('touchmove', this.onDrag);
            document.addEventListener('touchend', this.stopDrag);
        } else {
            document.addEventListener('mousemove', this.onDrag);
            document.addEventListener('mouseup', this.stopDrag);
        }
        this.containerWidth = this.container.current.clientWidth - 50;
    }

    onDrag = e => {
        if (this.unmounted || this.state.unlocked) return;
        if (this.isDragging) {
            if (isTouchDevice) {
                this.sliderLeft = Math.min(Math.max(0, e.touches[0].clientX - this.startX), this.containerWidth);
            } else {
                this.sliderLeft = Math.min(Math.max(0, e.clientX - this.startX), this.containerWidth);
            }
            this.updateSliderStyle();
        }
    };

    updateSliderStyle = () => {
        if (this.unmounted || this.state.unlocked) return;
        this.slider.current.style.left = (this.sliderLeft + 50) + 'px';
    };

    stopDrag = async () => {
        if (this.unmounted || this.state.unlocked) return;
        if (this.isDragging) {
            this.isDragging = false;
            if (this.sliderLeft > this.containerWidth * 0.9) {
                this.sliderLeft = this.containerWidth;
                await this.onSuccess();
            } else {
                this.sliderLeft = 0;
                if (this.props.onFailure) {
                    this.props.onFailure();
                }
            }
            this.updateSliderStyle();
        }
    };

    startDrag = e => {
        if (this.unmounted || this.state.unlocked) return;
        this.isDragging = true;
        if (isTouchDevice) {
            this.startX = e.touches[0].clientX;
        } else {
            this.startX = e.clientX;
        }
    };

    onSuccess = async () => {
        this.setState({ loading: true });
        let failed = false;
        try {
            await this.props.onSuccess();
        } catch (error) {
            failed = true;
            console.error(error);
        }
        this.setState({ loading: false });
        // this.container.current.style.width = this.container.current.clientWidth + 'px';
        this.setState({
            unlocked: true,
            failed,
            loading: false,
        });
    };

    getBgColor() {
        if (this.state.unlocked && this.state.failed) {
            return this.props.failureColor;
        } else if (this.state.unlocked) {
            return this.props.successColor;
        } else if (this.state.loading) {
            return this.props.loadingColor;
        } else {
            return this.props.color;
        }
    }

    getText = () => {
        if (this.state.loading) {
            return this.props.loadingText;
        }
        if (this.state.failed) {
            return this.props.textFailed;
        }
        if (this.state.unlocked) {
            return this.props.textUnlocked;
        }
        return this.props.text;
    };

    reset = () => {
        if (this.unmounted) return;
        this.setState({ unlocked: false, failed: false }, () => {
            this.sliderLeft = 0;
            this.updateSliderStyle();
        });
    };

    componentWillUnmount() {
        this.unmounted = true;
    }

    render() {
        return (
            <ReactSwipeButton>
                <RsbContainer
                    unlocked={this.state.unlocked}
                    ref={this.container}>
                    <RsbcSlider
                        ref={this.slider}
                        onMouseDown={this.startDrag}
                        bgColor={this.getBgColor()}
                        onTouchStart={this.startDrag}>
                        <RsbcSliderText>{this.getText()}</RsbcSliderText>
                        <RsbcSliderArrow>
                            <svg x="0px" y="0px"
                                 width="24px" height="24px" viewBox="0 0 24 24">
                                <g transform="matrix(1 0 0 -1 0 1638)">
                                    <path d="M16.5,1630.688h-2.484c0,0.734-0.168,1.305-0.504,1.711s-0.848,0.609-1.535,0.609c-1.336,0-2.004-0.777-2.004-2.332v-2.801
		h8.578v-9.07H7.125c-0.461,0-0.855,0.164-1.184,0.492s-0.492,0.727-0.492,1.195v7.383H7.5v2.812c0,1.406,0.43,2.594,1.289,3.562
		s1.93,1.453,3.211,1.453s2.352-0.484,3.211-1.453S16.5,1632.094,16.5,1630.688z"/>
                                </g>
                            </svg>
                        </RsbcSliderArrow>
                        <RsbcSliderCircle bgColor={this.getBgColor()}/>
                    </RsbcSlider>
                    <RsbcText>{this.getText()}</RsbcText>
                </RsbContainer>
            </ReactSwipeButton>
        );
    }
}