import React, { useState, useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Api from './Api';
import Token from './Token';
import { store } from './store';
import Wrapper from './Wrapper';

const LoginTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;


const Error = styled.div`
  text-align: center;
  font-size: 20px;
  background: #FF8B86;
  padding: 5px 10px;
  border: 1px solid #FF3F34;
  color: #000;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  padding: 5px;
  display: block;
`;

const Input = styled.input`
  font-size: 14px;
  padding: 9px 10px;
  border: 2px solid #AAA;
  border-radius: 10px;
  width: 100%;
`;

const Button = styled.button`
  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  background: #7dca5c;
  color: #FFF;
  border-radius: 10px;
  width: 100%;
  border: none;
`;

const PasswordLink = styled.a`
  font-size: 12px;
  display: block;
  color: #000;
  margin: 10px auto 0;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

const usedUsernameKey = "dwd_used_username";

function Authenticate({ domain }) {

    const [username, setUsername] = useState(localStorage.getItem(usedUsernameKey) || '');
    const [password, setPassword] = useState('');
    const [authType, setAuthType] = useState('db');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { dispatch } = useContext(store);

    const authenticateUser = useCallback((e) => {
        e.preventDefault();

        if (!validateEmail(username) && password.length < 2) {
            setError(true);
            return;
        }

        setLoading(true);

        Api.postWithoutAuth('/auth/login', {
            email: username,
            password,
            url: window.location.host,
        }).then((response) => {
            localStorage.setItem(usedUsernameKey, username);
            if (response.data.auth_url) {
                window.location.href = response.data.auth_url;
                return;
            }
            Token.set(response.data.access_token);
            dispatch({ type: 'login' });
            setError(false);
            setLoading(false);
        }).catch(() => {
            setError(true);
            setLoading(false);
        });
    }, [username, password]);

    const askForAuthType = async (username) => {
        if (!validateEmail(username)) {
            setAuthType('db');
            return;
        }

        const response = await Api.getWithoutAuth(`/auth-type?email=${username}`);

        setAuthType(response.data.auth_type);

    };

    const optimizedFn = useCallback(debounce(askForAuthType), []);


    const changeUserName = (value) => {
        setUsername(value);
        optimizedFn(value);
    };

    useEffect(() => {
        const savedName = localStorage.getItem(usedUsernameKey);
        if (savedName) {
            askForAuthType(savedName);
        }
    }, []);

    const getAuthString = () => {

        if (authType === 'microsoft_graph') {
            if (loading) {
                return 'Redirecting to Offce 365...';
            }
            return 'Login with Office 365';
        }


        if (loading) {
            return 'Logging in...';
        }
        return 'Login';
    };

    return (
        <Wrapper domain={domain}>
            <form onSubmit={authenticateUser}>
                {error && (
                    <Error>
                        Login incorrect!
                    </Error>
                )}
                <InputWrapper>
                    <Label>Email</Label>
                    <Input type="text" value={username} onChange={(e) => {
                        changeUserName(e.target.value);
                    }}/>
                </InputWrapper>
                {authType === 'db' && (
                    <InputWrapper>
                        <Label>Password</Label>
                        <Input type="password" value={password} onChange={(e) => {
                            setPassword(e.target.value);
                        }}/>
                    </InputWrapper>
                )}

                <div>
                    <Button type="submit" disabled={loading}>
                        {getAuthString()}
                    </Button>
                </div>
                {authType === 'db' && (
                    <PasswordLink href="/forgot-password">
                        Forgot your password?
                    </PasswordLink>
                )}
            </form>

        </Wrapper>
    );
}

export default Authenticate;
