import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { store } from './store';
import Authenticate from './Authenticate';
import Doors from './Doors';
import Api from './Api';

const Loading = styled.div`
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
`;
const ReloadWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    padding: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ReloadButton = styled.button`
    font-size: 16px;
    margin-top: 10px;
    padding: 5px 10px;
    text-align: center;
    background: #05C46B;
    color: #FFF;
    border-radius: 10px;
    width: 100%;
    border: none;
    box-shadow: -1px 10px 46px -21px rgba(0, 0, 0, 0.75);
`;

function App() {
    const { state, dispatch } = useContext(store);
    const [domain, setDomain] = useState(null);
    const { authenticated, reload } = state;

    const changeDomainSettings = (domainId) => {
        document.querySelector('#manifest').setAttribute('href', `${window.location.protocol}//${window.location.hostname}${process.env.REACT_APP_BACKEND_PREFIX}/${domainId}/manifest.json`);
        document.querySelector('#favicon').setAttribute('href', `${window.location.protocol}//${window.location.hostname}${process.env.REACT_APP_BACKEND_PREFIX}/${domainId}/favicon.ico`);
        document.querySelector('#apple-touch').setAttribute('href', `${window.location.protocol}//${window.location.hostname}${process.env.REACT_APP_BACKEND_PREFIX}/${domainId}/apple-touch-icon.png`);
        document.querySelector('#favicon-32').setAttribute('href', `${window.location.protocol}//${window.location.hostname}${process.env.REACT_APP_BACKEND_PREFIX}/${domainId}/favicon-32x32.png`);
        document.querySelector('#favicon-16').setAttribute('href', `${window.location.protocol}//${window.location.hostname}${process.env.REACT_APP_BACKEND_PREFIX}/${domainId}/favicon-16x16.png`);
    };

    useEffect(() => {

        Api.getWithoutAuth(`/auth/domains?uri=${window.location.host}`).then(r => {
            const currentDomain = r.data;
            if (currentDomain) {
                setDomain(currentDomain);
                changeDomainSettings(currentDomain.id);
            }
        }).catch(() => {
            dispatch({ type: 'reload' });
        });
    }, []);


    if (reload) {
        return <ReloadWrapper>
            <div>Something went wrong!</div>
            <ReloadButton onClick={() => {
                window.location.reload(false);
            }}>Reload app</ReloadButton>
        </ReloadWrapper>;
    }

    if (authenticated === null) {
        return (
            <Loading>
                Checking authentication...
            </Loading>
        );
    }

    if (domain === null) {
        return (
            <Loading>
                Loading info...
            </Loading>
        );
    }

    if (!authenticated) {
        return (
            <Authenticate domain={domain}/>
        );
    }

    return (
        <Doors domain={domain}/>
    );
}

export default App;
