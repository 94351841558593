// store.js
import React, { createContext, useReducer, useEffect } from 'react';
import Token from './Token';
import Api from './Api';


const initialState = {
    authenticated: null,
    reload: false,
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'login':
                return { ...state, authenticated: true, reload: false };
            case 'logout':
                return { ...state, authenticated: false, reload: false };
            case 'reload':
                return { ...state, reload: true };
            default:
                throw new Error();
        }
        ;
    }, initialState);

    useEffect(() => {
        const token = Token.get();
        if (!token) {
            dispatch({ type: 'logout' });
        } else {
            Api.post('/auth/refresh').then((response) => {
                const newToken = response.data.access_token;
                Token.set(newToken);
                dispatch({ type: 'login' });
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch({ type: 'logout' });
                    Token.remove();
                } else {
                    dispatch({ type: 'reload' });

                }

            });
        }
    }, []);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
